import React, { createRef, useEffect, useMemo, useRef, useState } from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Accordion } from '@abyss/web/ui/Accordion';
import { Alert } from '@abyss/web/ui/Alert';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { isEmpty, isNil, isUndefined } from 'lodash';
import { Loader } from '@src/components/Loader';
import { TextInput } from '@abyss/web/ui/TextInput';
import { useApi } from '@src/context/Api';
import { useRoutesContext } from '@src/context/Routes';
import { Visibility } from '@src/components/Visibility';
import { Form } from './components/Form';
import { CreateModal } from './components/CreateModal';

/**
 * List: CommonCriteria
 *
 * Provides the user with a screen listing the common criteria to be used for filtering risk records.
 *
 * @returns {Element}
 * @constructor
 */
export const List = () => {
  const { currentRoute } = useRoutesContext();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [currentEntityId, setCurrentEntityId] = useState('');
  const [currentEntityName, setCurrentEntityName] = useState('');
  const [isLoadingAssets, setIsLoadingAssets] = useState(false);

  const triggerRefs = useRef([]);

  const { useApiQueries } = useApi();

  const theAssets = [
    'ListActionStatuses',
    'ListTags',
    {
      key: 'ListActionPaths',
      args: { page: 0, size: 9999, sort: 'name,asc', statusList: ['ACTIVE'] },
    },
  ];

  const assets = useApiQueries(theAssets);

  /**
   * Determines the overall loading state of all asset queries.
   */
  useEffect(() => {
    if (
      !isEmpty(assets) &&
      Object.keys(assets).length === theAssets.length &&
      isEmpty(
        Object.keys(assets).filter((assetKey) => {
          const asset = assets[assetKey];
          return !(!asset?.isLoading && !asset?.isFetching);
        })
      )
    ) {
      setIsLoadingAssets(false);
    } else {
      setIsLoadingAssets(true);
    }
  }, [assets, theAssets]);

  /**
   * Mock data for the Common Criteria items.
   *
   * @TODO Replace this with an API call.
   *
   * @type {[{name: string, id: string, filters: [{condition: string, column: string, value: string[]},{condition:
   *   string, column: string, value: string[]},{condition: string, column: string, value: number},{condition: string,
   *   column: string, value: number},{condition: string, column: string, value: string[]},null]},{name: string, id:
   *   string, filters: [{condition: string, column: string, value: string[]},{condition: string, column: string,
   *   value: string[]},{condition: string, column: string, value: string[]},{condition: string, column: string, value:
   *   number}]},{name: string, id: string, filters: [{condition: string, column: string, value: string[]},{condition:
   *   string, column: string, value: string[]}]}]}
   */
  const data = useMemo(() => {
    return [
      {
        id: '1',
        name: 'CommonCriteria item #1',
        filters: [
          {
            column: 'TAGS',
            condition: 'ALL',
            value: ['UA-DOB-CIP', 'TS-FARO', 'TS-IMDM', 'TS-TRANSUNION', 'LB-H_OPT'],
          },
          {
            column: 'TAGS',
            condition: 'NONE',
            value: ['TS-CIP', 'US-FARO', 'US-TRANSUNION', 'US-IMDM'],
          },
          {
            column: 'UNTRUSTED_SOURCE_COUNT',
            condition: 'LT',
            value: 2,
          },
          {
            column: 'TRUSTED_SOURCE_COUNT',
            condition: 'GTE',
            value: 3,
          },
          {
            column: 'TAGS',
            condition: 'ANY',
            value: ['DB-DAY', 'DB-MONTH', 'DB-YEAR', 'DB-FLIP', 'DB-OD_US'],
          },
          {
            column: 'TOTAL_TRUSTED_DOB_CHANGE_COUNT',
            condition: 'LTE',
            value: 3,
          },
        ],
      },
      {
        id: '2',
        name: 'CommonCriteria item #2',
        filters: [
          {
            column: 'TAGS',
            condition: 'ALL',
            value: ['US-IMDM', 'TS-IMDM', 'RC-03'],
          },
          {
            column: 'TAGS',
            condition: 'ANY',
            value: ['TS-CIP', 'US-CIP'],
          },
          {
            column: 'TAGS',
            condition: 'NONE',
            value: ['US-HSID', 'TS-HSID'],
          },
          {
            column: 'UNTRUSTED_SOURCE_COUNT',
            condition: 'LT',
            value: 2,
          },
        ],
      },
      {
        id: '3',
        name: 'CommonCriteria item #3',
        filters: [
          {
            column: 'TAGS',
            condition: 'ALL',
            value: ['US-FARO', 'RC-03', 'RS-R'],
          },
          {
            column: 'TAGS',
            condition: 'ANY',
            value: ['DB-FD_US', 'DB-OD_US', 'DB-OD_TS', 'DB-FD_TS'],
          },
        ],
      },
    ];
  }, []);

  const actionPaths = assets?.ListActionPaths?.data || [];
  const actionStatuses = assets?.ListActionStatuses?.data || [];
  const tags = assets?.ListTags?.data || [];

  triggerRefs.current = data?.map((item, index) => {
    const currentRef = triggerRefs?.current[index];

    if (!isNil(currentRef)) {
      return currentRef;
    }

    return createRef();
  });

  /**
   * Set the value of the current item when the active item changes.
   */
  useEffect(() => {
    const currentItem = data?.find((item) => {
      return item?.id === currentEntityId;
    });

    if (!isNil(currentItem)) {
      setCurrentEntityName(currentItem?.name);
    }
  }, [currentEntityId]);

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/CommonCriteria/List/List.jsx">
      <Visibility
        accessor={currentRoute?.accessor}
        enabledEnvironments={['Local', 'Development']}
        disabledEnvironments={['Stage', 'Production']}
      >
        {isUndefined(data) || isLoadingAssets ? (
          <Loader verticalAlignment="top" />
        ) : (
          <Grid>
            <Grid.Col
              span={{
                xs: '100%',
              }}
            >
              <Flex direction="row" alignItems="center">
                <Heading offset={0}>Common Criteria</Heading>
                <Button
                  before={<IconSymbol icon="add" />}
                  css={{
                    marginLeft: themeConfiguration?.theme?.space?.md,
                  }}
                  size="$sm"
                  variant="outline"
                  onClick={() => {
                    setIsCreateModalOpen(true);
                  }}
                  isDisabled={isLoadingAssets}
                >
                  Create
                </Button>
              </Flex>
            </Grid.Col>
            <Grid.Col
              span={{
                xs: '100%',
              }}
            >
              {isUndefined(data) ? (
                <Alert title="No Common Criteria defined." variant="info" />
              ) : (
                <Accordion isCollapsible defaultValue={currentEntityId} onValueChange={setCurrentEntityId}>
                  {data.map((item, index) => {
                    return (
                      <Accordion.Item key={`${item?.name}`} value={item?.id}>
                        <Accordion.Trigger
                          onClick={(event) => {
                            if (!isEmpty(currentEntityId)) {
                              event?.preventDefault();
                            }
                          }}
                          ref={triggerRefs?.current?.[index]}
                        >
                          <Accordion.Header>
                            {currentEntityId === item?.id ? (
                              <TextInput
                                label="Name"
                                hideLabel
                                value={currentEntityName}
                                width={`${Number(themeConfiguration?.theme?.space?.xl?.replace('px', '')) * 8}px`}
                                onChange={(event) => {
                                  return setCurrentEntityName(event?.target?.value);
                                }}
                              />
                            ) : (
                              item?.name
                            )}
                          </Accordion.Header>
                        </Accordion.Trigger>
                        <Accordion.Content>
                          <Form
                            actionPaths={actionPaths}
                            actionStatuses={actionStatuses}
                            currentEntity={item}
                            handleCollapse={() => {
                              return triggerRefs?.current?.[index]?.current?.click();
                            }}
                            name={currentEntityName}
                            setCurrentEntityId={setCurrentEntityId}
                            tags={tags}
                          />
                        </Accordion.Content>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              )}
              {isCreateModalOpen && (
                <CreateModal
                  actionPaths={actionPaths}
                  actionStatuses={actionStatuses}
                  isOpen={isCreateModalOpen}
                  setIsOpen={setIsCreateModalOpen}
                  tags={tags}
                />
              )}
            </Grid.Col>
          </Grid>
        )}
      </Visibility>
    </ErrorHandler>
  );
};
