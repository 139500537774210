/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { config } from '@abyss/web/tools/config';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty, isNull, orderBy } from 'lodash';
import { NavMenuPrimitives } from '@abyss/web/ui/NavMenu';
import { PageHeaderPrimitives } from '@abyss/web/ui/PageHeader';
import { Sticky } from 'react-sticky';
import { useRoutesContext } from '@src/context/Routes';
import { useUserContext } from '@src/context/User';
import { Visibility } from '@src/components/Visibility';
import { NavIconTitle } from './components/NavIconTitle';
import { LogoutStyles, Styles, SubNavStyles } from './includes/styles';

/**
 * Header
 *
 * Renders the logo and navigation which "sticks" as the user scrolls vertically.
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const Header = () => {
  const { displayName } = useUserContext();
  const { currentRoute, currentRoutes } = useRoutesContext();

  /**
   * Determines the currently active navigation menu item, based on the current route.
   */
  useEffect(() => {
    if (!isEmpty(currentRoute)) {
      const navigationItems = document.querySelectorAll(
        '.remhub-header .abyss-nav-menu-menu, .remhub-header .abyss-nav-menu-link-root '
      );

      if (!isEmpty(navigationItems)) {
        navigationItems.forEach((navigationItem) => {
          navigationItem?.classList?.remove('active');
          if (
            currentRoute?.path === navigationItem?.pathname ||
            (navigationItem?.pathname !== '/' && currentRoute?.path?.includes(navigationItem?.pathname)) ||
            navigationItem?.innerText === currentRoute?.navigationLabel ||
            navigationItem?.innerText === currentRoute?.parent?.navigationLabel
          ) {
            navigationItem?.classList.add('active');
          }
        });
      }
    }
  });

  /**
   * RenderUserMenu
   *
   * Displays the user menu.
   *
   * @returns {Element}
   * @constructor
   */
  const RenderUserMenu = () => {
    return (
      <NavMenuPrimitives.Item>
        <NavMenuPrimitives.Trigger variant="inverted">
          <NavIconTitle iconName="account_box" title={displayName} />
        </NavMenuPrimitives.Trigger>
        <NavMenuPrimitives.Content>
          <LogoutStyles>
            <NavMenuPrimitives.Columns>
              <NavMenuPrimitives.Column>
                <NavMenuPrimitives.MenuItem title="Logout" href="/logout" />
              </NavMenuPrimitives.Column>
            </NavMenuPrimitives.Columns>
          </LogoutStyles>
        </NavMenuPrimitives.Content>
      </NavMenuPrimitives.Item>
    );
  };

  /**
   * RenderSubMenuItems
   *
   * Displays the sub-menu items.
   *
   * @param items
   * @returns {Element}
   * @constructor
   */
  const RenderSubMenuItems = ({ items = [] }) => {
    const theItems = orderBy(items, ['menuOrder'], ['asc']);

    return (
      <React.Fragment>
        {!isEmpty(theItems) && (
          <SubNavStyles>
            <NavMenuPrimitives.Columns>
              <NavMenuPrimitives.Column>
                {theItems?.map((subItem) => {
                  if (subItem?.showInNavigation === true) {
                    return (
                      <NavMenuPrimitives.MenuItem
                        key={`${subItem?.accessor}-childItem`}
                        title={subItem?.navigationLabel}
                        href={subItem?.path}
                        className={
                          currentRoute?.path === subItem?.path || currentRoute?.path?.includes(subItem?.path)
                            ? 'active'
                            : ''
                        }
                      />
                    );
                  }
                  return <React.Fragment key={`${subItem?.accessor}-childItem`} />;
                })}
              </NavMenuPrimitives.Column>
            </NavMenuPrimitives.Columns>
          </SubNavStyles>
        )}
      </React.Fragment>
    );
  };

  /**
   * RenderNavigationItems
   *
   * Renders the navigation items.
   *
   * @param items
   * @returns {Element}
   * @constructor
   */
  const RenderNavigationMenu = ({ items = [] }) => {
    const theItems = orderBy(items, ['menuOrder'], ['asc']);

    return (
      <React.Fragment>
        {!isEmpty(theItems) &&
          theItems?.map((item) => {
            if (item?.path !== '/' && item?.showInNavigation === true) {
              if (item?.children && !item?.hideSubNav) {
                return (
                  <NavMenuPrimitives.Item key={`${item?.accessor}-parentItem`}>
                    <NavMenuPrimitives.Trigger variant="inverted">
                      <NavIconTitle iconName={item?.navigationIcon} title={item?.navigationLabel} />
                    </NavMenuPrimitives.Trigger>
                    <NavMenuPrimitives.Content>
                      <RenderSubMenuItems items={item?.children} />
                    </NavMenuPrimitives.Content>
                  </NavMenuPrimitives.Item>
                );
              }
              return (
                <NavMenuPrimitives.Link href={item?.path} variant="inverted" key={`${item?.accessor}-parentLink`}>
                  <NavIconTitle iconName={item?.navigationIcon} title={item?.navigationLabel} />
                </NavMenuPrimitives.Link>
              );
            }
            return <React.Fragment key={`${item?.accessor}-parentItem`} />;
          })}
      </React.Fragment>
    );
  };

  return (
    <ErrorHandler location="src/layouts/default/Header/Header.jsx">
      <Visibility>
        <Styles>
          <Sticky topOffset={40}>
            {({ style, isSticky }) => {
              const pageHeader = document.querySelector('.abyss-page-header-container');

              if (!isNull(pageHeader)) {
                if (isSticky) {
                  pageHeader.classList.add('sticky');
                } else {
                  pageHeader.classList.remove('sticky');
                }
              }
              return (
                <div id="app-header" style={style}>
                  <PageHeaderPrimitives.Container fullWidth className="remhub-header">
                    <PageHeaderPrimitives.Brandmark logoTitle={config('APP_NAME')} logoRedirect="/" />
                    <div>
                      <NavMenuPrimitives.Provider positionViewportToTrigger>
                        <NavMenuPrimitives.Root variant="inverted">
                          <NavMenuPrimitives.List>
                            <RenderNavigationMenu items={currentRoutes} />
                            <RenderUserMenu />
                          </NavMenuPrimitives.List>
                          <NavMenuPrimitives.Viewport />
                        </NavMenuPrimitives.Root>
                      </NavMenuPrimitives.Provider>
                    </div>
                  </PageHeaderPrimitives.Container>
                </div>
              );
            }}
          </Sticky>
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};
