import React, { useCallback, useState } from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Button } from '@src/components/Button';
import { config } from '@abyss/web/tools/config';
import { Divider } from '@abyss/web/ui/Divider';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Filters } from '@src/components/Filters';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { TextInput } from '@abyss/web/ui/TextInput';
import { useForm } from '@abyss/web/hooks/useForm';
import PropTypes from 'prop-types';

/**
 * CreateModal
 *
 * Prompts the user with a popup window allowing them to create a new set of common criteria filters.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const CreateModal = (props) => {
  const { actionPaths, actionStatuses, tags, isOpen, setIsOpen } = props;

  const [isLoading, setIsLoading] = useState(false);

  const defaultValues = {
    id: '',
    name: '',
    filters: [],
  };

  const form = useForm({ defaultValues });

  const { isDirty, isValid, isSubmitting } = form?.formState;

  /**
   * handleClose
   *
   * Handles the closing of the modal.
   *
   * @returns {Promise<void>}
   */
  const handleClose = () => {
    setIsOpen(false);
  };

  /**
   * handleSubmit
   *
   * Calls a remote API to save the common criteria.
   *
   * @returns {Promise<void>}
   */
  const handleSubmit = useCallback(async () => {
    if (!isLoading && isValid && !isSubmitting && isDirty) {
      setIsLoading(true);
      try {
        // console.log('handleSubmit', submittedValues);
      } catch (error) {
        let theError = error;

        if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
          theError = JSON.stringify(error);
        }

        console.error(
          'src/routes/private/Admin/screens/currentEntity/List/components/Form/Form.jsx -> handleSubmit():',
          theError
        );
      }
      setIsLoading(false);
    }
  }, [isLoading, isDirty, isValid, isSubmitting, form?.formState?.errors]);

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/Codes/List/components/FormModal/FormModal.jsx">
      <Modal title="Create Common Criteria" isOpen={isOpen} onClose={handleClose} size="full">
        <FormProvider state={form} autoComplete="off" onSubmit={handleSubmit}>
          <Modal.Section>
            <Grid>
              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                <TextInput
                  model="name"
                  label="Name"
                  width={`${Number(themeConfiguration?.theme?.space?.xl?.replace('px', '')) * 8}px`}
                />
              </Grid.Col>
              <Grid.Col span={{ xs: '100%' }}>
                <Filters
                  actionPaths={actionPaths}
                  actionStatuses={actionStatuses}
                  form={form}
                  isLoading={isLoading}
                  tags={tags}
                />
              </Grid.Col>
            </Grid>
          </Modal.Section>
          <Modal.Section>
            <Divider height={1} />
            <Layout.Group alignLayout="right">
              <Button variant="outline" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="solid" type="submit">
                Create
              </Button>
            </Layout.Group>
          </Modal.Section>
        </FormProvider>
      </Modal>
    </ErrorHandler>
  );
};

CreateModal.propTypes = {
  actionPaths: PropTypes.shape({
    create: PropTypes.string,
  }),
  actionStatuses: PropTypes.shape({
    create: PropTypes.string,
  }),
  tags: PropTypes.arrayOf(PropTypes.string),
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

CreateModal.defaultProps = {
  actionPaths: {},
  actionStatuses: {},
  tags: [],
  isOpen: false,
  setIsOpen: () => {},
};
