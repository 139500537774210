import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Visibility } from '@src/components/Visibility';
import { Widget } from '@src/components/Widget';
import { Grid } from '@abyss/web/ui/Grid';
import { dayjs } from '@abyss/web/tools/dayjs';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { RemoveModal } from './components/RemoveModal';
import { AddModal } from './components/AddModal';

/**
 * Widget: ActionPathNotes
 *
 * Allows adding/removing EID's to/from an active/manual action path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ActionPathNotes = (props) => {
  const { actionPath } = props;

  const [showAddModal, setShowAddModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  return (
    <ErrorHandler location="src/widgets/ActionPathNotes/ActionPathNotes.jsx">
      <Widget
        title="Notes"
        description={`Last updated by: ${actionPath?.lastModifiedBy} on ${dayjs(actionPath?.lastModifiedDate).format(
          'MM/DD/YYYY'
        )}`}
      >
        <Grid>
          <Grid.Col span={{ xs: '100%' }}>
            <pre
              style={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                fontFamily: 'var(--abyss-fonts-primary)',
              }}
            >
              {actionPath?.note}
            </pre>
          </Grid.Col>
          <Visibility accessor="actionPath-notes" enabledUserRoles={['State.Write']}>
            <Grid.Col span={{ xs: '50%' }}>
              <Button
                variant="solid"
                after={<IconSymbol icon="edit" variant="outlined" size="20" />}
                onClick={() => {
                  return setShowAddModal(true);
                }}
              >
                Edit Note
              </Button>
            </Grid.Col>
            <Grid.Col span={{ xs: '50%' }} css={{ textAlign: 'right' }}>
              <Button
                variant="destructive"
                after={<IconSymbol icon="delete" variant="outlined" size="20" />}
                onClick={() => {
                  return setShowRemoveModal(true);
                }}
              >
                Delete Note
              </Button>
            </Grid.Col>
          </Visibility>
        </Grid>
      </Widget>
      <Visibility accessor="actionPath-notes" enabledUserRoles={['State.Write']}>
        <AddModal actionPath={actionPath} isOpen={showAddModal} setIsOpen={setShowAddModal} />
        <RemoveModal actionPath={actionPath} isOpen={showRemoveModal} setIsOpen={setShowRemoveModal} />
      </Visibility>
    </ErrorHandler>
  );
};

ActionPathNotes.propTypes = {
  actionPath: PropTypes.shape({
    id: PropTypes.string,
    lastModifiedBy: PropTypes.string,
    lastModifiedDate: PropTypes.string,
    note: PropTypes.string,
  }),
};

ActionPathNotes.defaultProps = {
  actionPath: {},
};
