import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { config } from '@abyss/web/tools/config';
import { isArray, isUndefined } from 'lodash';
import { useMsal } from '@azure/msal-react';
import { useApi } from './Api';

/**
 *
 * @type {{emailAddress: string, displayName: string, roles: *[], tenantId: string, localAccountId: string,
 *   homeAccountId: string}}
 */
const defaultValues = {
  displayName: config('APP_ENV') === 'local' ? 'Some User' : '',
  emailAddress: config('APP_ENV') === 'local' ? 'user@email.com' : '',
  homeAccountId: '',
  localAccountId: '',
  msalAccount: {},
  roles:
    config('APP_ENV') === 'local'
      ? [config('AUTHORIZATION_ROLE'), 'State.Write', 'State.Read', 'State.Admin', 'Dashboard.Read']
      : [],
  tenantId: '',
  hasAccess: null,
};

/**
 *
 * @type {React.Context<{emailAddress: string, displayName: string, roles: *[], tenantId: string, localAccountId:
 *   string, homeAccountId: string}>}
 */
const UserContext = createContext(defaultValues);

/**
 *
 * @returns {{emailAddress: string, displayName: string, roles: *[], tenantId: string, localAccountId: string,
 *   homeAccountId: string}}
 */
export const useUserContext = () => {
  return useContext(UserContext);
};

/**
 * UserProvider
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const UserProvider = (props) => {
  const { children } = props;

  const { useApiQuery } = useApi();

  const [GetUserRoles, { data: delegatedRoles }] = useApiQuery('GetUserRoles');

  const { instance } = useMsal();

  const [displayName, setDisplayName] = useState(defaultValues.displayName);
  const [emailAddress, setEmailAddress] = useState(defaultValues.emailAddress);
  const [homeAccountId, setHomeAccountId] = useState(defaultValues.homeAccountId);
  const [localAccountId, setLocalAccountId] = useState(defaultValues.localAccountId);
  const [msalAccount, setMsalAccount] = useState(defaultValues.msalAccount);
  const [roles, setRoles] = useState(defaultValues.roles);
  const [tenantId, setTenantId] = useState(defaultValues.tenantId);
  const [hasAccess, setHasAccess] = useState(defaultValues.hasAccess);

  /**
   * Synchronize the roles from IDP with user context.
   */
  useEffect(() => {
    if (config('APP_ENV') !== 'local') {
      const activeAccount = instance.getActiveAccount();

      if (isArray(activeAccount?.idTokenClaims?.roles) && activeAccount?.idTokenClaims?.roles !== roles) {
        setRoles(activeAccount?.idTokenClaims?.roles);
        const access = activeAccount?.idTokenClaims?.roles.includes(config('AUTHORIZATION_ROLE'));
        setHasAccess(access);
      } else {
        setHasAccess(false);
      }
    }
  }, [instance]);

  /**
   * Retrieve the delegated roles on behalf of the service user from the API and merge them with the user roles
   */
  useEffect(() => {
    if (config('APP_ENV') !== 'local') {
      (async () => {
        if (hasAccess === true && isUndefined(delegatedRoles)) {
          await GetUserRoles();
        } else if (isArray(delegatedRoles)) {
            const theRoles = [...roles, ...delegatedRoles];
            setRoles(theRoles);
          }
      })();
    }
  }, [hasAccess, delegatedRoles]);

  /**
   * Synchronize the displayName from IDP with user context.
   */
  useEffect(() => {
    if (config('APP_ENV') !== 'local') {
      const activeAccount = instance.getActiveAccount();

      if (activeAccount?.idTokenClaims?.name !== displayName) {
        setDisplayName(activeAccount?.idTokenClaims?.name);
      }
    }
  }, [instance]);

  /**
   * Synchronize the email address from IDP with user context.
   */
  useEffect(() => {
    if (config('APP_ENV') !== 'local') {
      const activeAccount = instance.getActiveAccount();

      if (activeAccount?.idTokenClaims?.email !== emailAddress) {
        setEmailAddress(activeAccount?.idTokenClaims?.email);
      }
    }
  }, [instance]);

  /**
   * Synchronize the homeAccountId from IDP with user context.
   */
  useEffect(() => {
    if (config('APP_ENV') !== 'local') {
      const activeAccount = instance.getActiveAccount();

      if (activeAccount?.homeAccountId !== homeAccountId) {
        setHomeAccountId(activeAccount?.homeAccountId);
      }
    }
  }, [instance]);

  /**
   * Synchronize the localAccountId from IDP with user context.
   */
  useEffect(() => {
    if (config('APP_ENV') !== 'local') {
      const activeAccount = instance.getActiveAccount();

      if (activeAccount?.localAccountId !== localAccountId) {
        setLocalAccountId(activeAccount?.localAccountId);
      }
    }
  }, [instance]);

  /**
   * Synchronize the msalAccount from IDP with user context.
   */
  useEffect(() => {
    if (config('APP_ENV') !== 'local') {
      const activeAccount = instance.getActiveAccount();

      if (activeAccount !== msalAccount) {
        setMsalAccount(activeAccount);
      }
    }
  }, [instance]);

  /**
   * Synchronize the tenantId from IDP with user context.
   */
  useEffect(() => {
    if (config('APP_ENV') !== 'local') {
      const activeAccount = instance.getActiveAccount();

      if (activeAccount?.tenantId !== tenantId) {
        setTenantId(activeAccount?.tenantId);
      }
    }
  }, [instance]);

  const value = useMemo(() => {
    return {
      displayName,
      emailAddress,
      homeAccountId,
      localAccountId,
      msalAccount,
      roles,
      tenantId,
      hasAccess,
    };
  }, [displayName, emailAddress, homeAccountId, localAccountId, msalAccount, roles, tenantId, hasAccess]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
