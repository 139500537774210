import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Divider } from '@abyss/web/ui/Divider';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { isEmpty, omit, orderBy, sumBy } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { Widget } from '@src/components/Widget';
import { Styles } from './includes/styles';
import { Table } from '../../components/Table';
import { DonutChart } from '../../components/Chart';

/**
 * widget: ActionStatusCodes
 *
 * Displays information about entity action path statuses.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ActionStatusCodes = (props) => {
  const { recordReferenceStatusCodeCounts } = props;

  const [theData, setTheData] = useState({});
  const [noData, setNoData] = useState(false);

  /**
   * prepare data for display in the chart and the table
   */
  useEffect(() => {
    if (!isEmpty(recordReferenceStatusCodeCounts)) {
      const labels = [];
      const data = [];

      Object.keys(omit(recordReferenceStatusCodeCounts, ['total'])).forEach((statusCode) => {
        if (!statusCode.includes('Perc')) {
          labels.push(statusCode);

          data.push({
            type: statusCode,
            percent: recordReferenceStatusCodeCounts[`${statusCode}Perc`],
            amount: recordReferenceStatusCodeCounts[statusCode],
          });
        }
      });

      labels.sort((a, b) => {
        return a.localeCompare(b);
      });

      setTheData({ labels, data: orderBy(data, ['type'], ['asc']) });
    }
  }, [recordReferenceStatusCodeCounts]);

  /**
   * Check if there is no data to display
   */
  useEffect(() => {
    if (isEmpty(theData?.data) || (!isEmpty(theData?.data) && sumBy(theData?.data, 'amount') === 0)) {
      setNoData(true);
    } else {
      setNoData(false);
    }
  }, [theData?.data]);

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/screens/View/widgets/ActionStatusCodes/ActionStatusCodes.jsx">
      <Widget title="Action Status Codes" description="Percentage by action codes">
        <Styles>
          {noData ? (
            <Layout.Stack alignLayout="center">
              <div>
                <IconSymbol icon="bid_landscape_disabled" color="$gray3" size="96" />
              </div>
              <div>
                <Text color="$gray4" fontWeight="bold">
                  Data Not Available
                </Text>
              </div>
            </Layout.Stack>
          ) : (
            <DonutChart labels={theData?.labels} data={theData?.data} />
          )}{' '}
          <Divider />
          <Table labels={theData?.labels} data={theData?.data} />
        </Styles>
      </Widget>
    </ErrorHandler>
  );
};

ActionStatusCodes.propTypes = {
  recordReferenceStatusCodeCounts: PropTypes.shape({
    total: PropTypes.number,
    active: PropTypes.number,
    activePerc: PropTypes.number,
    resolved: PropTypes.number,
    resolvedPerc: PropTypes.number,
    canceled: PropTypes.number,
    canceledPerc: PropTypes.number,
    invalidated: PropTypes.number,
    invalidatedPerc: PropTypes.number,
  }),
};

ActionStatusCodes.defaultProps = {
  recordReferenceStatusCodeCounts: {},
};
