import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@src/components/Button';
import { config } from '@abyss/web/tools/config';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Filters } from '@src/components/Filters';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { isEmpty, isUndefined } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { useForm } from '@abyss/web/hooks/useForm';

/**
 * Form
 *
 * Displays a form to create or update a set of common criteria filters.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Form = (props) => {
  const { actionPaths, actionStatuses, currentEntity, handleCollapse, name, setCurrentEntityId, tags } = props;

  const [isLoading, setIsLoading] = useState(false);

  const defaultValues = {
    id: '',
    name: '',
    filters: [],
  };

  const form = useForm({ defaultValues });

  const { isDirty, isValid, isSubmitting } = form?.formState;

  /**
   * Mapping currentEntity loaded from API to the form state.
   */
  useEffect(() => {
    if (!isUndefined(currentEntity?.filters) && !isEmpty(currentEntity?.filters)) {
      const data = defaultValues;

      data.id = currentEntity?.id;
      data.name = currentEntity?.name || name;

      if (!isUndefined(currentEntity?.filters)) {
        data.filters = currentEntity?.filters.map((filter) => {
          const theFilter = {
            field: filter?.column,
            condition: filter?.condition,
            value: filter?.value,
          };

          if (['LAST_MODIFIED_DATE', 'CREATED_DATE'].includes(theFilter?.field)) {
            theFilter.value = dayjs(theFilter?.value).format('MM/DD/YYYY');
          }

          if (theFilter?.field?.toLowerCase().includes('count')) {
            theFilter.value = String(theFilter?.value);
          }

          return theFilter;
        });
      }

      form?.reset(data, {
        keepDirty: false,
        keepDirtyValues: false,
        keepErrors: false,
        keepIsValid: false,
        keepSubmitCount: true,
        keepTouched: false,
        keepValues: false,
      });
    }
  }, [currentEntity]);

  /**
   * handleSubmit
   *
   * Calls a remote API to save the common criteria.
   *
   * @returns {Promise<void>}
   */
  const handleSubmit = useCallback(async () => {
    if (!isLoading && isValid && !isSubmitting && isDirty) {
      setIsLoading(true);
      try {
        // console.log('handleSubmit', submittedValues);
      } catch (error) {
        let theError = error;

        if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
          theError = JSON.stringify(error);
        }

        console.error(
          'src/routes/private/Admin/screens/currentEntity/List/components/Form/Form.jsx -> handleSubmit():',
          theError
        );
      }
      setIsLoading(false);
    }
  }, [isLoading, isDirty, isValid, isSubmitting, form?.formState?.errors]);

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/currentEntity/List/components/Form/Form.jsx">
      <FormProvider state={form} autoComplete="off" highlighted onSubmit={handleSubmit}>
        <Grid>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Filters
              actionPaths={actionPaths}
              actionStatuses={actionStatuses}
              form={form}
              isLoading={isLoading}
              tags={tags}
            />
          </Grid.Col>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Layout.Group alignLayout="left">
              <Button variant="solid" type="submit" isDisabled={(name === currentEntity?.name && !isDirty) || !isValid}>
                Save
              </Button>
              <Button
                variant="outline"
                onClick={async () => {
                  await setCurrentEntityId('');
                  handleCollapse();
                }}
              >
                Cancel
              </Button>
            </Layout.Group>
          </Grid.Col>
        </Grid>
      </FormProvider>
    </ErrorHandler>
  );
};

Form.propTypes = {
  actionPaths: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  actionStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  currentEntity: PropTypes.shape({
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        column: PropTypes.string,
        condition: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
      })
    ),
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  handleCollapse: PropTypes.func,
  name: PropTypes.string,
  setCurrentEntityId: PropTypes.func,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

Form.defaultProps = {
  actionPaths: [],
  actionStatuses: [],
  currentEntity: {},
  handleCollapse: () => {},
  name: '',
  setCurrentEntityId: () => {},
  tags: [],
};
